<template>
    <v-dialog
        v-model="dialog"
        width="800"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                <v-icon small left>$add</v-icon> Add User
            </v-btn>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-card-title class="grey lighten-2">
                    Add new User
                </v-card-title>

                <v-card-text>
                    <v-row class="mt-4">
                        <v-col cols=6>
                            <client-selector :rules="[ruleRequired]" v-model="client" />
                        </v-col>
                        <v-col cols=12>
                            <i-text-field label="Email" v-model="email" hide-details="auto" email :rules="rulesEmail" />
                        </v-col>
                        <v-col cols=6>
                            <v-text-field
                                v-model="password"
                                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rulesPassword"
                                :type="passwordShow ? 'text' : 'password'"
                                name="input-10-1"
                                label="Password"
                                counter
                                outlined
                                dense
                                hide-details="auto"
                                @click:append="passwordShow = !passwordShow"
                            />
                        </v-col>
                        <v-col cols=6>
                            <v-text-field
                                v-model="passwordConfirm"
                                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[ruleRequired, passwordMatch]"
                                :type="passwordShow ? 'text' : 'password'"
                                name="input-10-1"
                                label="Confirm Password"
                                hide-details="auto"
                                outlined
                                dense
                                @click:append="passwordShow = !passwordShow"
                            />
                        </v-col>
                        <v-col cols=6>
                            <i-text-field
                                label="First Name"
                                v-model="firstName"
                                :rules="[ruleRequired]"
                            />
                        </v-col>
                        <v-col cols=6>
                            <i-text-field
                                label="Last Name"
                                v-model="lastName"
                                :rules="[ruleRequired]" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        text
                        @click="addUser"
                    >
                        SAVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    // import bus from '@/ebus';
    import ClientSelector from '@/components/User/ClientSelector.vue';
    import rules from '@/utils/validation';
    import ManageAPI from '../../webapi';

    export default {
        name: 'UserAddModal',

        components: {
            ClientSelector,
        },

        data: () => ({
            passwordShow: false,
            valid: true,
            dialog: false,
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            password: undefined,
            passwordConfirm: undefined,
            client: undefined,

            // RULES
            rulesEmail: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                // rules.required(),
                // rules.email(),
            ],
            rulesPassword: [
                rules.required(),
                rules.min({
                    errorMessage: 'At least 8 characters.',
                    threshold: 8,
                }),
                rules.containsUppercase(),
                rules.containsSpecialCharacter(),
            ],
            rulesPasswordConfirm: [
                rules.required(),
                rules.min({
                    errorMessage: 'At least 8 characters.',
                    threshold: 8,
                }),
                rules.containsUppercase(),
                rules.containsSpecialCharacter(),
            ],
            ruleRequired: rules.required(),
        }),

        computed: {
            passwordMatch() {
                return () => (this.password === this.passwordConfirm || 'Passwords do not match.');
            },
        },

        methods: {
            addUser() {
                // Validate the form
                const formValidated = this.$refs.form.validate();
                if (formValidated === true) {
                    const payload = {
                        email: this.email,
                        password: this.password,
                        first_name: this.firstName,
                        last_name: this.lastName,
                        client: this.client,
                    };
                    ManageAPI.createUser(payload).then(() => {
                        this.$emit('on-success');
                        this.dialog = false;
                    }).catch((err) => {
                        this.$emit('on-error', err);
                    });
                }
            },
        },
    };
</script>
