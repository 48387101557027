<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row>
            <v-col cols=12 class="text-left">
                <user-add-modal @on-success="onUserAddSuccess" @on-error="onUserAddError" />
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Users Manage</v-card-title>

                    <v-simple-table class="text-left">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Client</th>
                                    <th>Last Login</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr @click="navigateToUser(user.id)" v-for="user in users" :key="user.id">
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.first_name }}</td>
                                    <td>{{ user.last_name }}</td>
                                    <td>
                                        <span v-if="user.client">
                                            {{ user.client.name }}
                                        </span>
                                    </td>
                                    <td>{{ user.last_login | localDateTime}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
    </request>
</template>

<script>
    import ManageAPI from '../webapi';
    import UserAddModal from '../modules/user/AddModal.vue';

    export default {
        name: 'ManageUserList',

        components: {
            UserAddModal,
        },

        data: () => ({
            request: () => ManageAPI.listUser(),
            users: [],
        }),

        methods: {
            onRequestSuccess(data) {
                this.users = data.results;
            },
            navigateToUser(userId) {
                this.$router.push({ name: 'manage-users-single', params: { userId } });
            },
            onUserAddSuccess() {
                this.$helpers.notifySuccess('User added.');
                this.request = () => ManageAPI.listUser();
            },
            onUserAddError(err) {
                this.$helpers.notifyError(`Error adding user ${err}`);
            },
        },
    };
</script>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
